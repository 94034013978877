// Utils
import { verifyBuild } from "@/client/utils/verify-build";

/* eslint-disable no-console */

const debug = (...logs: unknown[]) =>
  console.log(
    "%cLog:",
    "background:navajowhite;color:sienna;padding:2px;font-style:italic",
    ...logs,
  );
const info = (...infos: unknown[]) =>
  console.info(
    "%cInfo:",
    "background:#0068d7;color:ghostwhite;padding:2px;font-style:italic",
    ...infos,
  );

const warn = (...warns: unknown[]) =>
  console.warn(
    "%cWarning:",
    "background:#f57e2e;color:ghostwhite;padding:2px;font-style:italic",
    ...warns,
  );

const crucial = (...errors: unknown[]) =>
  console.error(
    "%cError:",
    "background:#f64f00;color:ghostwhite;padding:2px;font-style:italic",
    ...errors,
  );

const middleware = (name: string, ...logs: unknown[]) => {
  const { isDevelopment } = verifyBuild();
  isDevelopment &&
    console.log(`\x1b[36m Middleware ${name} \x1b[0m`, "\x1b[33m", ...logs, "\x1b[0m");
};

export const log = { debug, info, warn, crucial, middleware };
