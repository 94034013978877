// Core
import type { ReactNode } from "react";
import { forwardRef, BaseSyntheticEvent } from "react";
import cx from "classnames";

// Components
import { Input, type InputRef } from "antd";
import InputMask, { InputState } from "react-input-mask";

// Definitions
import { InputProps } from "antd";

// Utils
import st from "./styles.module.css";

const FORMAT_CHARS = { "1": "." };
const PHONE_INPUT_MASK = "11 11 11 11 11 11 1";

export type InputRefBaseType = InputRef | null;

interface IInputMasked {
  hasPhoneMask?: boolean;
  maskPlaceholder?: string;
  unmaskedValue?: boolean;
}

export interface IInputBase extends InputProps, IInputMasked {
  placeholder?: string;
  maxLength?: number;
  autoComplete?: "on" | "off";
  hasAutoComplete?: boolean;
  hasDisabled?: boolean;
  size?: "middle" | "large" | "small";
  shape?: "reg-num";
  isTextCentered?: boolean;
  hintText?: ReactNode;
  value?: string | readonly string[] | number | undefined;
  onChange?: (event: BaseSyntheticEvent) => void;
}

export const InputBase = forwardRef<InputRefBaseType, IInputBase>((props, ref) => {
  const {
    className,
    hasAutoComplete = false,
    hasDisabled = false,
    size = "large",
    type = "text",
    shape,
    maxLength = 100,
    placeholder = "Input",
    isTextCentered = false,
    hasPhoneMask = false,
    maskPlaceholder = null,
    unmaskedValue = false,
    onChange,
    value = "",
    hintText = null,
    onBlur,
    ...rest
  } = props;
  const autoCompleteValue = hasAutoComplete ? "on" : "off";

  const inputStyle = cx(
    st.input,
    {
      [st.disabled]: hasDisabled,
      [st.number]: type === "number",
      [st.centered]: isTextCentered,
    },
    shape && [st[`shape-${shape}`]],
    className,
  );

  const onChangeMaskInput = (event: BaseSyntheticEvent<object, EventTarget, { value: string }>) => {
    const maskValue = event.target?.value;
    event.target.value = unmaskedValue ? maskValue.replace(/[^\d|+]/g, "") : maskValue;
    onChange && onChange(event);
  };

  const beforeMaskedValueChange = (newState: InputState) => {
    const { selection } = newState;
    const cursorPosition = selection ? selection.start : null;
    return {
      value: newState.value.trim(),
      selection: { start: Number(cursorPosition) + 1, end: Number(cursorPosition) + 1 },
    };
  };

  return !hasPhoneMask ? (
    <>
      <Input
        {...rest}
        type={type}
        className={inputStyle}
        size={size}
        autoComplete={autoCompleteValue}
        disabled={hasDisabled}
        ref={ref}
        maxLength={maxLength}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {hintText}
    </>
  ) : (
    <>
      <InputMask
        mask={PHONE_INPUT_MASK}
        maskChar={maskPlaceholder}
        formatChars={FORMAT_CHARS}
        value={value}
        onChange={onChangeMaskInput}
        onBlur={onBlur}
        beforeMaskedValueChange={beforeMaskedValueChange}
      >
        {
          // @ts-expect-error render props
          (inputProps: IInputBase) => (
            <Input
              {...inputProps}
              {...rest}
              type={type}
              className={inputStyle}
              size={size}
              autoComplete={autoCompleteValue}
              ref={ref}
              maxLength={maxLength}
              placeholder={placeholder}
            />
          )
        }
      </InputMask>
      {hintText}
    </>
  );
});
InputBase.displayName = "InputBase";
