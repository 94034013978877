// Core
import axios, { AxiosResponse } from "axios";

// Definitions
import { HttpResponseAxiosErrorType } from "@/common/types/http";

// Utils
import { CLIENT_API_URL_HTTP_REST } from "./http-config";

// for browser requests
export const http = axios.create({
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  baseURL: CLIENT_API_URL_HTTP_REST,
  withCredentials: true,
  timeout: 10000,
});

http.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: HttpResponseAxiosErrorType) => {
    return Promise.reject(error);
  },
);
