// Store
import { useStore } from "@/client/hooks/use-store";

// Definitions
import { UserCountryCodesEnum } from "@/client/domains/user/types";

type UseUserCountryType = () => {
  country: keyof typeof UserCountryCodesEnum;
};

export const useUserCountry: UseUserCountryType = () => {
  const {
    geo: { country },
  } = useStore();

  return { country };
};
