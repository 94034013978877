export const languageIcons = [
  {
    value: "en",
    icon: "CountryGb",
  },
  {
    value: "at",
    icon: "CountryAt",
  },
  {
    value: "be",
    icon: "CountryBe",
  },
  {
    value: "bg",
    icon: "CountryBg",
  },
  {
    value: "hr", // Croatia
    icon: "CountryHr",
  },
  {
    value: "cy", // Cyprus
    icon: "CountryCy",
  },
  {
    value: "cz", // Czechia
    icon: "CountryCz",
  },
  {
    value: "dk",
    icon: "CountryDk",
  },
  {
    value: "ee",
    icon: "CountryEe",
  },
  {
    value: "fo", // Faroe Islands
    icon: "CountryFo",
  },
  {
    value: "fi",
    icon: "CountryFi",
  },
  {
    value: "fr",
    icon: "CountryFr",
  },
  {
    value: "de",
    icon: "CountryDe",
  },
  {
    value: "gr",
    icon: "CountryGr",
  },
  {
    value: "nl",
    icon: "CountryNl",
  },
  {
    value: "hu",
    icon: "CountryHu",
  },
  {
    value: "ie",
    icon: "CountryIe",
  },
  {
    value: "it",
    icon: "CountryIt",
  },
  {
    value: "lv",
    icon: "CountryLv",
  },
  {
    value: "lt",
    icon: "CountryLt",
  },
  {
    value: "lu",
    icon: "CountryLu",
  },
  {
    value: "mt", // Malta
    icon: "CountryMt",
  },
  {
    value: "no",
    icon: "CountryNo",
  },
  {
    value: "pl",
    icon: "CountryPl",
  },
  {
    value: "pt",
    icon: "CountryPt",
  },
  {
    value: "ro",
    icon: "CountryRo",
  },
  {
    value: "sk", // Slovakia
    icon: "CountrySk",
  },
  {
    value: "si", // Slovenia
    icon: "CountrySi",
  },
  {
    value: "es", // Spain
    icon: "CountryEs",
  },
  {
    value: "se", // Sweden
    icon: "CountrySe",
  },
  {
    value: "ch",
    icon: "CountryCh",
  },
];

export const countryIcons = [
  {
    value: "gb",
    icon: "CountryGb",
  },
  {
    value: "at",
    icon: "CountryAt",
  },
  {
    value: "be",
    icon: "CountryBe",
  },
  {
    value: "bg",
    icon: "CountryBg",
  },
  {
    value: "hr", // Croatia
    icon: "CountryHr",
  },
  {
    value: "cy", // Cyprus
    icon: "CountryCy",
  },
  {
    value: "cz",
    icon: "CountryCz",
  },
  {
    value: "dk",
    icon: "CountryDk",
  },
  {
    value: "ee",
    icon: "CountryEe",
  },
  {
    value: "fo", // Faroe Islands
    icon: "CountryFo",
  },
  {
    value: "fi",
    icon: "CountryFi",
  },
  {
    value: "fr",
    icon: "CountryFr",
  },
  {
    value: "de",
    icon: "CountryDe",
  },
  {
    value: "gr",
    icon: "CountryGr",
  },
  {
    value: "nl",
    icon: "CountryNl",
  },
  {
    value: "hu",
    icon: "CountryHu",
  },
  {
    value: "ie",
    icon: "CountryIe",
  },
  {
    value: "it",
    icon: "CountryIt",
  },
  {
    value: "lv",
    icon: "CountryLv",
  },
  {
    value: "lt",
    icon: "CountryLt",
  },
  {
    value: "lu",
    icon: "CountryLu",
  },
  {
    value: "mt", // Malta
    icon: "CountryMt",
  },
  {
    value: "no",
    icon: "CountryNo",
  },
  {
    value: "pl",
    icon: "CountryPl",
  },
  {
    value: "pt",
    icon: "CountryPt",
  },
  {
    value: "ro",
    icon: "CountryRo",
  },
  {
    value: "sk", // Slovakia
    icon: "CountrySk",
  },
  {
    value: "si", // Slovenia
    icon: "CountrySi",
  },
  {
    value: "es",
    icon: "CountryEs",
  },
  {
    value: "se",
    icon: "CountrySe",
  },
  {
    value: "ch",
    icon: "CountryCh",
  },
];
