import cx from "classnames";

// Components
import { Space as AntSpace } from "antd";

// Definitions
import { SpaceProps as AntSpaceProps } from "antd";
import { SpaceCompactProps } from "antd/lib/space/Compact";

// Modules
import st from "./styles.module.css";

interface SpaceProps extends AntSpaceProps {
  type?: "phone" | "compact";
}
export const Space = ({ type, className = "", ...rest }: SpaceProps) => {
  const spaceStyles = cx(type && { [st[type]]: !!type }, className);

  return <AntSpace className={spaceStyles} {...rest} />;
};

const Compact = (props: SpaceCompactProps) => {
  return <AntSpace.Compact {...props} />;
};
Space.Compact = Compact;
