const BUILD_ENV = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
};

export const verifyBuild = (): {
  isDevelopment: boolean;
  isProduction: boolean;
} => {
  const isDev = process.env.NEXT_PUBLIC_BUILD_ENV === BUILD_ENV.DEVELOPMENT;
  const isProduction = process.env.NEXT_PUBLIC_BUILD_ENV === BUILD_ENV.PRODUCTION;

  return {
    isDevelopment: isDev,
    isProduction,
  };
};
