// Core
import { PropsWithChildren, ReactNode } from "react";
import cx from "classnames";

// Components
import { Form, Skeleton } from "antd";
import { InputLabel } from "../InputLabel";
import { Link, LinkProps } from "../../Link";

// Definitions
import { FormItemProps, ValidateStatus } from "antd/lib/form/FormItem";
import { SkeletonInputProps } from "antd/lib/skeleton/Input";

// Utils
import st from "./styles.module.css";

interface IInputFormProps extends FormItemProps, PropsWithChildren {
  children: ReactNode;
  id?: string;
  name?: string | Array<string>;
  label?: string;
  extraLabel?: ReactNode;
  labelRequired?: boolean;
  loading?: boolean;
  validateStatus?: ValidateStatus;
  type?:
    | "base"
    | "no-gutter"
    | "no-last"
    | "small"
    | "middle"
    | "search-bar"
    | "header"
    | "footer"
    | "";
  skeletonType?: "group" | "counter";
  skeletonSize?: SkeletonInputProps["size"];
  skeletonRows?: number;
  noStyle?: boolean;
  linkProps?: { text: string; shallow?: boolean } & LinkProps;
  linkComponent?: React.ElementType;
  testId?: string;
}

export const InputForm = (props: IInputFormProps) => {
  const {
    children,
    label,
    type = "base",
    skeletonType,
    skeletonRows = 1,
    skeletonSize = "large",
    labelRequired = false,
    loading = false,
    hasFeedback = false,
    linkProps,
    testId,
    linkComponent: LabelLink = Link,
    className,
    extraLabel,
    ...rest
  } = props;

  const itemStyle = cx(className, st["form-item"], !!type && { [st[`form-item--${type}`]]: type });
  const skeletonStyle = cx(
    st.skeleton,
    !!skeletonType && { [st[`skeleton--${skeletonType}`]]: !!skeletonType },
  );

  return (
    <Form.Item
      {...rest}
      data-testid={testId}
      className={itemStyle}
      label={
        label ? (
          <InputLabel
            required={labelRequired}
            text={label}
            linkProps={linkProps}
            linkComponent={LabelLink}
            extraLabel={extraLabel}
          />
        ) : null
      }
      hasFeedback={hasFeedback}
    >
      {loading ? (
        <>
          {[...Array(skeletonRows).keys()].map((i) => (
            <Skeleton.Input key={i} className={skeletonStyle} size={skeletonSize} active />
          ))}
        </>
      ) : (
        children
      )}
    </Form.Item>
  );
};
