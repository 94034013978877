// Core
import { PropsWithChildren } from "react";

// Components
import { Form as FormAntd } from "antd";

// Definitions
import { FormProps as FormPropsAntd } from "antd/lib/form/Form";

const FORM_BASE_CONFIG: FormPropsAntd = {
  labelCol: {
    xs: { span: 24 },
  },
  layout: "vertical",
  labelAlign: "left",
  name: "pro-trader-form",
  size: "large",
  method: "post",
};
interface FormProps<T = void> extends FormPropsAntd<T> {
  name: string;
}
type FormPropsType<T = void> = PropsWithChildren<FormProps<T>>;

export const Form = (props: FormPropsType) => {
  const { children, size, ...otherProps } = props;

  const formConfig = {
    ...FORM_BASE_CONFIG,
    ...otherProps,
    size: size || FORM_BASE_CONFIG.size,
  };

  return <FormAntd {...formConfig}>{children}</FormAntd>;
};
