"use client";

// Core
import cx from "classnames";

// Components
import { Tooltip as TooltipAntd } from "antd";

// Definitions
import type { TooltipProps as AntTooltipProps } from "antd";

// Utils
import st from "./styles.module.css";

export type TooltipProps = AntTooltipProps & {
  gutter?: "no-gutter" | "small" | "middle" | "large";
  type?: "secondary" | "form-hint" | "table-hint";
  color?: "default" | "blue" | "white";
};

export const Tooltip = (props: TooltipProps) => {
  const { children, className, gutter = "small", type, color = "default", ...rest } = props;

  const tooltipStyle = cx(
    st.tooltip,
    color && { [st[`tooltip-color-${color}`]]: color },
    gutter && { [st[`tooltip-gutter-${gutter}`]]: gutter },
    type && { [st[`tooltip-type-${type}`]]: type },
    className,
  );

  return (
    <TooltipAntd overlayClassName={tooltipStyle} {...rest}>
      {children}
    </TooltipAntd>
  );
};
