// Components
import { Icon } from "@/client/components/ui/Icon";

// Definitions
import {
  IconType as NotificationIconType,
  ArgsProps as NotificationArgsProps,
} from "antd/lib/notification/interface";

// Utils
import st from "./styles.module.css";

const notificationConfig: Partial<NotificationArgsProps> = {
  className: st.notification,
  duration: 2,
  placement: "topRight",
};

const getIcon = (type: NotificationIconType) => {
  switch (type) {
    case "success":
      return "InfoSuccess";
    case "error":
      return "InfoError";
    case "warning":
      return "InfoWarning";
    default:
      return "InfoWarning";
  }
};

export type NotificationType = NotificationIconType;

export type NotificationMessage = {
  title: string;
  desc: string;
};

export type NotificationConfigProps = Omit<NotificationArgsProps, "message" | "description"> &
  NotificationMessage;

export const notificationView = (
  { title, desc, ...restConfig }: NotificationConfigProps,
  type: NotificationType = "info",
): NotificationArgsProps => {
  return {
    ...notificationConfig,
    ...restConfig,
    icon: <Icon className={st.notification__icon} name={getIcon(type)} size="full-width" />,
    message: <span className={`${st.title} ${st[type]}`}>{title}</span>,
    ...(desc && { description: <span className={st.desc}>{desc}</span> }),
  };
};
