// Components
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { InputSelect, InputSelectProps } from "@/client/components/ui/FormFields/InputSelect";

// Definitions
import type { ValidateStatus } from "antd/lib/form/FormItem";

/* eslint @typescript-eslint/no-explicit-any: 0 */
interface IWrappedFormSelect<ControlT extends FieldValues> extends InputSelectProps {
  name: Path<ControlT>;
  placeholder?: string;
  control?: Control<ControlT>;
  validateStatus?: ValidateStatus;
}

export const WrappedFormSelect = <T extends FieldValues>(props: IWrappedFormSelect<T>) => {
  const { name, control, validateStatus, ...rest } = props;

  const isValid = validateStatus !== "error";

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputSelect hasValid={isValid} {...rest} {...field} />}
    />
  );
};
