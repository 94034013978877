// Components
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { InputBase, IInputBase } from "@/client/components/ui/FormFields/InputBase";

/* eslint @typescript-eslint/no-explicit-any: 0 */
interface IWrappedFormInput<ControlT extends FieldValues> extends IInputBase {
  name: Path<ControlT>;
  control?: Control<ControlT>;
  autoComplete?: "on" | "off";
}

export const WrappedFormInput = <T extends FieldValues>(props: IWrappedFormInput<T>) => {
  const { name, control, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputBase {...rest} {...field} ref={field.ref} />}
    />
  );
};
