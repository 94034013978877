// Definitions
import { PropsWithChildren, ReactNode, ElementType } from "react";
import type { LinkProps } from "../../Link";

// Components
import { Link } from "../../Link";
import { Text } from "../../Typography/Text";

// Utils
import st from "./styles.module.css";

type InputLabelType = PropsWithChildren & {
  text: string;
  required?: boolean;
  linkProps?: LinkProps & { text: string };
  linkComponent?: ElementType;
  extraLabel?: ReactNode;
};

export const InputLabel = (props: InputLabelType) => {
  const { required, text, extraLabel = null, linkComponent: LinkRender = Link, linkProps } = props;

  const LabelBase = ({ children }: { children?: ReactNode }) => {
    return (
      <div className={st.label}>
        <Text size="14" color="gray-900" font="system" fontWeight="600">
          <>
            {text}
            {required && "*"}
          </>
        </Text>
        {extraLabel}
        {children}
      </div>
    );
  };

  if (linkProps) {
    const { text: linkText, ...otherLinkProps } = linkProps;
    return (
      <LabelBase>
        <LinkRender {...otherLinkProps}>{linkText}</LinkRender>
      </LabelBase>
    );
  }

  return <LabelBase />;
};
