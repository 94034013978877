"use client";

// Core
import { App } from "antd";

// Definitions
import type { NotificationConfigProps } from "@/client/components/ui/Notification";

// Components
import { notificationView } from "@/client/components/ui/Notification";

type UseNotification = {
  showSuccess: (config: NotificationConfigProps) => void;
  showError: (config: NotificationConfigProps) => void;
  showWarning: (config: NotificationConfigProps) => void;
  showInfo: (config: NotificationConfigProps) => void;
};
export const useNotification = (): UseNotification => {
  const { notification } = App.useApp();

  return {
    showSuccess: (config) => notification.open(notificationView(config, "success")),
    showError: (config) => notification.open(notificationView(config, "error")),
    showWarning: (config) => notification.open(notificationView(config, "warning")),
    showInfo: (config) => notification.open(notificationView(config, "info")),
  };
};
