// Core
import { FieldValues, FormState } from "react-hook-form";

// Definition
import type {
  BaseShapeFormType,
  ErrorsFields,
  FieldMetaHelpText,
  FieldMetaStatus,
  FieldsMetaType,
  SetErrorFnType,
  TouchedFields,
} from "@/client/components/form/form.types";
import type { ServerErrorFieldDetailsType } from "@/common/types/http";
import { i18n as i18nType } from "i18next";

// Utils
import { log } from "@/client/utils/browser-logger";
import { verifyBuild } from "@/client/utils/verify-build";

export const getFieldValidate = (
  key = "",
  touched: TouchedFields = {},
  errors: ErrorsFields = void 0,
): FieldMetaStatus => {
  const isInValid = errors?.[key];
  const isValid = touched[key] && !errors?.[key];
  return isInValid ? "error" : isValid ? "success" : void 0;
};

export const getFieldHelpText = (key = "", errors: ErrorsFields = void 0): FieldMetaHelpText => {
  const err = errors?.[key] || {};
  return { ...("message" in err ? { help: err.message as string } : {}) };
};

export function getFieldsMetaValidation<T extends FieldValues = BaseShapeFormType>(
  shape: BaseShapeFormType,
  formState: FormState<T>,
): {
  [key: string]: {
    status: FieldMetaStatus;
    helpText: FieldMetaHelpText;
  };
} {
  const { touchedFields, errors } = formState;

  return Object.keys(shape).reduce((acc, key) => {
    const status = getFieldValidate(key, touchedFields, errors);
    const helpText = getFieldHelpText(key, errors);
    const fieldMeta = {
      [key]: {
        status,
        helpText,
      },
    };
    return {
      ...acc,
      ...fieldMeta,
    };
  }, {});
}

export const getPartialFieldsMetaValidation = <Shape extends object>(
  shape: Record<keyof Shape, Shape[keyof Shape]>,
  touched: TouchedFields,
  errors: ErrorsFields,
  baseName: Array<string | number> = [],
): FieldsMetaType<Shape> => {
  return Object.keys(shape).reduce((acc, key) => {
    const status = getFieldValidate(key, touched, errors);
    const helpText = getFieldHelpText(key, errors);

    const fieldMeta = {
      [key]: {
        name: [...baseName, key].join("."),
        status,
        helpText,
      },
    };

    return {
      ...acc,
      ...fieldMeta,
    };
  }, {} as FieldsMetaType<Shape>);
};

export const fillServerErrorsToForm = <T = Record<string, string>>(args: {
  i18n: i18nType;
  errors: { [key: string]: ServerErrorFieldDetailsType };
  setError?: SetErrorFnType<T>;
}) => {
  const { i18n, errors, setError } = args;
  return (
    errors &&
    Object.keys(errors).forEach((key) => {
      const validationKey = `inputs:validationErrors.${errors[key].alias}`;
      if (!i18n.exists(validationKey) && verifyBuild().isDevelopment) {
        log.info("--- Missing validation key: ", errors[key].alias);
      }
      const message = i18n.exists(validationKey) ? i18n.t(validationKey) : errors[key].desc;
      setError?.(key as keyof T, {
        type: "server",
        message,
      });
    })
  );
};

export const getFieldLabel = (key: string, isPlaceholder?: boolean): string => {
  const entity = isPlaceholder ? "placeholder" : "label";
  return `inputs:${key}.${entity}`;
};
